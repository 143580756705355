@use "styles" as *;

.panel {
  margin-bottom: 80px;
  gap: 0;
  text-decoration: none;
  @include sm-desktop {
    margin-bottom: 120px;
  }
}

.panelMarginBottom {
  margin-bottom: 80px;
}

.video {
  z-index: initial;
}

.description {
  margin-top: 8px;
}
